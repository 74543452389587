<template>
  <el-container>
    <el-main>
      <!-- 图片 -->
      <el-row>
        <el-col :span="24" >
          <img src="../assets/imgs/承运端指南大图.jpg" style="height: 100%;width: 100%;"/>
          <!-- <lunbotu></lunbotu> -->
        </el-col>
      </el-row>
      <!-- 主体 -->
      <el-row style="margin-top: 50px;">
        <el-col :span="24">
          <el-row>
            <!-- 左侧菜单 -->
            <el-col :span="4"  :push="4" style="line-height: 50px;background-color: #F5F5F5;">
              <ul style="list-style-type: none;border: 1xp solid;font-size: 16px;" v-for="(item,index) in leftlist" :key="index">
                <li :class="active == index ? 'isActive' : '' "  @click="swich(index)">{{item.lefttitle}}</li>
              </ul>
              <!-- <el-row>
                <el-col :span="9" style="margin-left: 350px;line-height: 50px;background-color: #F5F5F5;"> -->
                  <!-- <router-link  to="/userchild1">组件1</router-link> -->
                  <!-- <ul style="list-style-type: none;border: 1xp solid;" v-for="(item,index) in leftlist" :key="index">
                    <li :class="active == index ? 'isActive' : '' "  @click="swich(index)">{{item.lefttitle}}</li>
                  </ul>
                </el-col>
              </el-row> -->
            </el-col>
            <!-- 右侧内容 -->
            <el-col :span="15" :push="3" :offset="2">
              <!-- <router-view></router-view> -->
              <el-row>
                <el-col v-if="p==0">
                  <!-- <router-link  to="/userchild1">组件1</router-link> -->
                  <carrierchild1></carrierchild1>
                </el-col>
                <el-col v-if="p==1">
                  <carrierchild2></carrierchild2>
                </el-col>
                <el-col v-if="p==2">
                  <carrierchild3></carrierchild3>
                </el-col>
                <el-col v-if="p==3">
                  <carrierchild4></carrierchild4>
                </el-col>
                <el-col v-if="p==4">
                  <carrierchild5></carrierchild5>
                </el-col>
                <el-col v-if="p==5">
                  <carrierchild6></carrierchild6>
                </el-col>
                <el-col v-if="p==6">
                  <carrierchild7></carrierchild7>
                </el-col>
              </el-row>
              <!-- <userchild1></userchild1> -->
            </el-col>
          </el-row>
      </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
// import lunbotu from './lunbotu'
import carrierchild1 from './carrierchild1'
import carrierchild2 from './carrierchild2'
import carrierchild3 from './carrierchild3'
import carrierchild4 from './carrierchild4'
import carrierchild5 from './carrierchild5'
import carrierchild6 from './carrierchild6'
import carrierchild7 from './carrierchild7'
export default {
  data () {
    return {
      active: -1,
      currentTab: 0,
      leftlist: [
        { lefttitle: '如何接单' },
        { lefttitle: '如何认证成物流公司' },
        { lefttitle: '如何绑定物流公司' },
        { lefttitle: '如何发布线路' },
        { lefttitle: '如何调度司机' },
        { lefttitle: '如何下整车单' },
        { lefttitle: '如何下零担单' }
      ],
      p: 0,
      i: 1,
      licolor: {
        color: 'black'
      }
    }
  },
  components: { carrierchild1, carrierchild2, carrierchild3, carrierchild4, carrierchild5, carrierchild6, carrierchild7 },
  methods: {
    swich (index) {
      this.p = index
      // console.log(this.i)
      this.active = index
    }
  }
}
</script>
<!-- <li class="cate-left-item " :class="{activeItem:currentTab==index}" @click="swich(index)" v-for="(item,index) in leftlist" :key="index"><label ></label>{{item.lefttitle}}</li> -->
<style>
  .isActive{
    color: #409EFF;
  }
  /* .isActive div{
    background-color: #00FFFF;
    width: 0.625rem;
    height: 0.1875rem;
  } */
</style>
