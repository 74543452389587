<template>
  <el-container>
    <el-main>
      <el-row>
      <el-col :span="24">
        <el-row>
          <el-col :span="5"  class="photo">
            <img class="imgsty" src="../assets/imgs/零担列表.jpg" />
          </el-col>
          <el-col :span="6" :push="1" :offset="1">
            <el-row style="margin-top: 150px;line-height: 30px;">
              <el-row><span style="font-size: 21px;">零担列表</span></el-row>
              <el-row class="smwrite">
                <span class="smfont">
                  选择某条线路，点击进零担的详情
                </span>
              </el-row>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="7" class="photo">
            <img class="imgsty" src="../assets/imgs/下单1.png" />
          </el-col>
          <el-col :span="6" :push="8" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">下单</span><br />
              <el-row class="smwrite">
                <span class="smfont">
                  选择提货方式和送货方式，如选上门提货和送货上门，需要填写地址（可选择多提多配）、填写货物信息、选择温度
                  ，点击下单，点击弹窗里的确定后跳转到支付页面
                </span>
              </el-row>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5"  class="photo">
            <img class="imgsty" src="../assets/imgs/货主端指南6.jpg" />
          </el-col>
          <el-col :span="6" :push="1" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">支付</span><br />
              <el-row class="smwrite">
                <span class="smfont">
                  选择支付方式，点击确认支付，当支付过后就会跳转到支付详情页面
                </span>
              </el-row>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="7" class="photo">
            <img class="imgsty" src="../assets/imgs/货主端指南6.jpg" />
          </el-col>
          <el-col :span="6" :push="8" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">支付详情</span><br />
              <el-row class="smwrite">
                <span class="smfont">
                  可查看订单详情
                </span>
              </el-row>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'carrierchild1'
}
</script>

<style>
  .smwrite{
    margin-top: 10px;
    color: #999999;
  }
  .smfont{
    font-size: 15px;
  }
  .imgsty{
    width: 130%;
    height: 130%;
  }
</style>
