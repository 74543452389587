<template>
  <el-container>
    <el-main>
      <el-row>
      <el-col :span="24">
        <el-row>
          <el-col :span="5"  class="photo">
            <img class="imgsty" src="../assets/imgs/发布1.png" />
          </el-col>
          <el-col :span="6" :push="1" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">发布页面</span><br />
              <el-row class="smwrite"><span class="smfont">点击添加线路按钮，跳转到发布线路页面</span></el-row>
             </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="7" class="photo">
            <img class="imgsty" src="../assets/imgs/发布线路.png" />
          </el-col>
          <el-col :span="6" :push="8" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">发布线路</span><br />
              <el-row class="smwrite"><span class="smfont">填全信息，点击发布，后续货主在您的线路上下单，订单会会直接到您个人中心待调度订单量</span></el-row>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5"  class="photo">
            <img class="imgsty" src="../assets/imgs/发布.png" />
          </el-col>
          <el-col :span="6" :push="1" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">发布列表</span><br />
              <el-row class="smwrite">
                <span class="smfont">
                  你可以新建线路，编辑删除已有线路
                </span>
              </el-row>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'carrierchild1'
}
</script>

<style>
  .smwrite{
    margin-top: 10px;
    color: #999999;
  }
  .smfont{
    font-size: 15px;
  }
  .imgsty{
    width: 130%;
    height: 130%;
  }
</style>
