<template>
  <el-container>
    <el-main>
      <!-- <div><img src="../assets/imgs/首页大图.jpg" style="width: 100%;height: 100%;" /></div> -->
      <el-row>
        <el-col :span="24">
      <el-row>
        <el-col :span="24">
          <img src="../assets/imgs/首页大图1.jpg" style="width: 100%;height: 100%;" />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="text-align: center;margin-top: 20px;">
          <el-row>
            <el-col>
              <span style="font-size: 21px;font-weight: 600;">公司简介</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <span style="font-size: 10px;">company introduction</span>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- 左图右字 -->
      <el-row>
        <el-col :push="3" :span="10" :sm="10" :lg="8" style="text-align: center;margin-top: 30px;">
           <img src="../assets/imgs/2.jpg"  style="width: 100%;height: 100%;"/>
          <!-- <el-row>
            <el-col :xs="12" :sm="10" :lg="6" style="border: 1px solid #000000;">
              <img src="../assets/imgs/2.jpg"  style="width: 440px;height: 280px;"/>
            </el-col>
            <el-col style="margin-top: 20px;border: 1px solid #000000;" :sm="10" :lg="8" >
              <span>
                <p style="width: 500px;text-align: left;line-height: 30px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;赤途(上海)供应链管理有限公司于2016年8月15日正式注册成立，总部位于上海市。</p>
                <p style="width: 500px;text-align: left;line-height: 30px;margin-top: -12px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;赤途主要从事供应链管理，仓储服务，道路货物运输，货物代理，汽车租赁，物流技术领域内的技术开发和技术咨询</p>
                <p style="width: 500px;text-align: left;line-height: 30px;margin-top: -12px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2016年3月，在赤途冷链平台项目尚未启动时期，赤途联合创始人便以扎实的思路，超越“互联网+冷链”发力线上、渗透线下的B2B2C传统思路。2016年4月-7月，赤途供应链秉持“多、快、好、省、”的四字方针，迅速筛选优化实体冷链企业8家，作为线下合作支持单位，整合车辆200台、冷库10万平、干线82条、布点城市41座带</p>
              </span>
            </el-col>
          </el-row> -->
        </el-col>
        <el-col :span="12" :offset="4" :sm="7" :lg="8" style="margin-top: 30px;flex-direction: column;">
          <span>
            <p style="ftext-align: left;line-height: 30px;font-size: 15px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;赤途(上海)供应链管理有限公司于2016年8月15日正式注册成立，总部位于上海市。</p>
            <p style="text-align: left;line-height: 30px;margin-top: -12px;font-size: 15px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;赤途主要从事供应链管理，仓储服务，道路货物运输，货物代理，汽车租赁，物流技术领域内的技术开发和技术咨询</p>
            <p style="text-align: left;line-height: 30px;margin-top: -12px;font-size: 15px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2016年3月，在赤途冷链平台项目尚未启动时期，赤途联合创始人便以扎实的思路，超越“互联网+冷链”发力线上、渗透线下的B2B2C传统思路。2016年4月-7月，赤途供应链秉持“多、快、好、省、”的四字方针，迅速筛选优化实体冷链企业8家，作为线下合作支持单位，整合车辆200台、冷库10万平、干线82条、布点城市41座带</p>
          </span>
        </el-col>
      </el-row>
      <!-- 公司产品 -->
      <el-row style="background-color: #F5F5F5;text-align: center;margin-top: 40px;height: 700px;">
        <el-col :span="24" >
          <el-row style="margin-top: 40px;">
            <el-col>
              <span style="font-size: 21px;font-weight: 600;">公司产品</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <span style="font-size: 10px;">company Portfolio</span>
            </el-col>
          </el-row>
          <el-row style="text-align: center;margin-top: 50px;">
            <el-row style="text-align: center;">
              <!-- 蓝色外边框 -->
              <el-col :span="16" :push="4" style="border:1px solid #00FFFF;height: 210px;margin-left: 35px;">
                <el-row>
                <el-col :span="12" :push="1" style="margin-top: 30px;text-align: left;">
                  <b style="font-size: 22px;">赤途冷链</b><br />
                  <span style="line-height: 30px;font-size: 15px;">为货主和货主公司提供手机APP、PC网站下整车单，零担单服务，在订单详情中随时查看货运状态，物流公司
                   与平台保证您的每一条订单都可以准时，安全到达。让货主更加放心，打造全程可视化、一站式稳定交付的温控管理平台
                  </span>
                </el-col>
                <el-col :span="3" :push="5"  style="border:1px solid #00FFFF;background-color: white;margin-top:11px;width: 200px;height: 100%px;">
                  <img src="../assets/imgs/公司产品右图.png" style="width: 145px;height: 180px;"/>
                </el-col>
                <!-- <el-col :span="2" :push="5" style="background-color: #409EFF;width: 100px;margin-top: 9%;margin-left: -1%;">
                  <a style="color: white;" href="#/user">了解更多</a>
                </el-col> -->
                </el-row>
              </el-col>
            </el-row>
            <el-row style="text-align: center;margin-top: 25px;">
              <!-- 蓝色外边框 -->
              <el-col :span="16" :push="4" style="border:1px solid #00FFFF;height: 210px;margin-left: 35px;">
                <el-row>
                <el-col :span="12" :push="8" :offset="2" style="margin-top: 30px;text-align: right;">
                  <b style="font-size: 22px;">赤途冷链</b><br />
                  <span style="line-height: 30px;font-size: 15px;">为货主和货主公司提供手机APP、PC网站下整车单，零担单服务，在订单详情中随时查看货运状态，物流公司
                   与平台保证您的每一条订单都可以准时，安全到达。让货主更加放心，打造全程可视化、一站式稳定交付的温控管理平台
                  </span>
                </el-col>
                <el-col :span="3" :pull="13" style="border:1px solid #00FFFF;background-color: #409EFF;margin-top:11px;width: 200px;height: 100%px;">
                  <img src="../assets/imgs/公司产品左图.png" style="width: 145px;height: 180px;"/>
                </el-col>
               <!-- <el-col :span="2" :pull="16" style="background-color: #409EFF;width: 100px;margin-top: 9%;margin-left: -1%;">
                  <a style="color: white;" href="#/user">了解更多</a>
                </el-col> -->
                </el-row>
              </el-col>
            </el-row>
          </el-row>
        </el-col>
      </el-row>
      <!-- 公司优势 -->
      <el-row style="text-align: center;">
        <el-col :span="24">
          <el-row style="margin-top: 40px;">
            <el-col>
              <span style="font-size: 21px;font-weight: 600;">公司优势</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <span style="font-size: 10px;">company advantage</span>
            </el-col>
          </el-row>
          <!-- 四个图片 -->
          <el-row style="margin-top: 20px;margin-left: 25px;" >
            <el-col :span="16" :push="5" style="text-align: center;margin-left: -35px;">
              <el-row>
                <el-col  :sm="5" class="adv" v-for="(item,index) in adv" :key="index"  style="border: 1px solid #8CC5FF;height: 240px; background-color:#EEEEEE;margin-left:18px;">
                  <el-row><img style="width:90% ;height: 150px;margin-top: 5px;" :src="item.imgsrc" /></el-row>
                  <el-row style="margin-top: 10px;">{{item.title}}</el-row>
                  <el-row style="margin-top: 6px;font-size: 12px;">{{item.smtitle}}</el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- 公新闻中心 -->
      <el-row style="margin-top: 50px;background-color: #F5F5F5;height: 600px;text-align: center;">
        <el-col :span="24">
          <!-- 大标题 -->
          <el-row style="margin-top: 40px;">
            <el-col>
              <span style="font-size: 21px;font-weight: 600;">新闻中心</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <span style="font-size: 10px;">company news</span>
            </el-col>
          </el-row>
          <!-- 两部分内容 -->
          <el-row style="margin-top: 30px;">
            <!-- 企业新闻 -->
            <el-col :span="12" :push="5">
                  <el-row>
                    <el-col :span="1" style="width: 8px;height: 24px;background-color: #409EFF;"></el-col>
                    <el-col :span="6" style="font-size: 20px;">企业新闻</el-col>
                  </el-row>
                  <el-row v-for="(item,index) in updatelogs" :key="index" style="margin-top: 25px;">
                    <el-col :span="4"  style="background-color: #409EFF;width: 60px;height: 60px;color: white;">
                      <el-row style="margin-top: 10px;">{{item.day}}</el-row>
                      <el-row style="font-size: 6px;">{{item.year}}</el-row>
                    </el-col>
                    <el-col :span="20" style="text-align: left; padding-left: 15px;padding-top: 9px;">
                      <el-row><a :href='item.toupdatepath' style="color: #000000;">{{item.updatetitle}}</a></el-row>
                      <el-row style="font-size: 15px;color: #72767B;margin-top: 7px;">{{item.updatewrite}}</el-row>
                    </el-col>
                  </el-row>
            </el-col>
             <!-- 行业知识 -->
            <el-col :span="12">
              <el-row>
                <el-col :span="1" style="width: 8px;height: 24px;background-color: #409EFF;"></el-col>
                <el-col :span="6" style="font-size: 20px;">行业知识</el-col>
              </el-row>
              <el-row style="margin-top: 25px;">
                <el-col :span="12" style="width: 230px;height: 350px;background-color: #F5F5F5;text-align: left;">
                  <el-row>
                    <el-col :span="24" :push="1" style="padding: 10px;">
                      <el-row><img src="../assets/imgs/1.jpg" style="width: 190px;height: 180px;"/></el-row>
                      <el-row style="font-size: 15px;">什么是落地配</el-row>
                      <el-row style="font-size: 12px;color: #B9B9B9;margin-top: 8px;">所谓落地配就是由落地分拨、同城和地县转运、入宅服务三大要素组成。它主要是以开箱验货、半收半退。夜间送货、试穿试用、选二选一、代收贷款、...</el-row>
                      <el-row style="margin-top: 10px;"><el-button type="primary" size="mini" round @click="tonews()"><router-link to="/hangyezhishi" style="color: white;">更多</router-link></el-button></el-row>
                    </el-col>
                  </el-row>
                </el-col>
               <el-col :span="12" style="width: 230px;height: 350px;background-color: #F5F5F5;text-align: left;margin-left: 20px;">
                 <el-row>
                   <el-col :span="24" :push="1" style="padding: 10px;">
                     <el-row><img src="../assets/imgs/1.jpg" style="width: 190px;height: 180px;"/></el-row>
                     <el-row style="font-size: 15px;">什么是干线运输</el-row>
                     <el-row style="font-size: 12px;color: #B9B9B9;margin-top: 8px;">干线运输是指运输网中起骨干作用的线路运输。按分布的区域范围划分，一般跨越省、区（市）的运输线（包括铁路线内河航线、沿海航线、航空线以及......）</el-row>
                     <el-row style="margin-top: 10px;"><el-button type="primary" size="mini" round><router-link to="/hangyezhishi" style="color: white;">更多</router-link></el-button></el-row>
                   </el-col>
                 </el-row>
               </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- 在线留言 -->
      <el-row style="margin-top: 20px;text-align: center;height: 500px;">
        <el-col :span="24">
          <!-- 大标题 -->
            <el-row style="margin-top: 40px;">
              <el-col>
                <span style="font-size: 21px;font-weight: 600;">在线留言</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <span style="font-size: 10px;">online message</span>
              </el-col>
            </el-row>
            <!-- 下面两个部分 -->
            <el-row style="margin-top: 25px;">
              <!-- 手机图片 -->
              <el-col :span="12" :push="3" style="margin-top: -50px;">
                <img src="../assets/imgs/在线留言左图.png" style="width: 45%;height: 45%;"/>
              </el-col>
              <!-- 表单 -->
              <el-col :span="12" :pull="3">
                <el-form ref="form" :model="form" label-width="80px">
                  <el-form-item>
                      <el-input style="width: 350px;" placeholder="姓名" v-model="form.name"></el-input>
                  </el-form-item>
                  <el-form-item>
                      <el-input style="width: 350px;" placeholder="电话" v-model="form.phone"></el-input>
                  </el-form-item>
                  <el-form-item>
                     <el-input style="width: 350px;" type="textarea" placeholder="请把您的需求留下,我们会在1-3个工作日联系您,为您提供专业的解决方案" v-model="form.desc"></el-input>
                  </el-form-item>
                  <el-form-item>
                        <el-button style="margin-left: -280px;" type="primary" @click="onSubmit">提交</el-button>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
        </el-col>
      </el-row>
      <!-- 联系方式 -->
      <el-row style="background-color: #34495C;height: 280px;">
        <el-col :span="24">
          <el-row style="margin-top: 60px;">
            <!-- 热线服务 -->
            <el-col :span="6" :push="4" style="text-align: center;color: white;">
              <el-row><img src="../assets/imgs/4.png" style="width: 40px;height: 40px;"/></el-row>
              <el-row style="margin-top: 10px;">24小时服务热线</el-row>
              <el-row style="margin-top: 10px;">400-9206-101</el-row>
            </el-col>
            <el-col :span="12" :push="5" style="border-left: 2px solid #7CA7D1;height: 140px;">
              <el-row style="text-align: center;">
                <el-col :span="12" :push="2">
                 <el-row><img  src="../assets/imgs/二维码.png" style="width: 30%;width: 30%;"/></el-row>
                 <el-row style="color: white;margin-top: 10px;">货主下单</el-row>
                </el-col>
                <el-col :span="12" :pull="2">
                  <el-row><img  src="../assets/imgs/二维码2.png" style="width: 30%;width: 30%;"/></el-row>
                  <el-row style="color: white;margin-top: 10px;">司机接单</el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- 页脚 -->
      <el-row style="text-align: center;background-color: #202A36;height: 50px;">
        <el-col :span="24" style="margin-top: 15px;">
          <span style="color: white;" class="jj">Copyright c 赤途（上海）供应链管理有限公司版权所有 丨 沪indicator-position备16055362号-1</span>
        </el-col>
      </el-row>
      </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
// import lunbotu from './lunbotu'
export default {
  name: 'Home',
  data () {
    return {
      form: {
        name: '',
        phone: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      imgs: [
        { imgsrc: './assets/imgs/1.jpg' },
        { imgsrc: './assets/imgs/1.jpg' },
        { imgsrc: './assets/imgs/1.jpg' }
      ],
      adv: [
        {
          id: 1,
          title: '自有车辆',
          smtitle: '自带经验司机',
          imgsrc: require('../assets/imgs/公司优势1.jpg')
        },
        {
          id: 2,
          title: '智能控温',
          smtitle: '货物一路领鲜',
          imgsrc: require('../assets/imgs/公司优势2.jpg')
        },
        {
          id: 3,
          title: '货损必赔',
          smtitle: '多重诚信保障',
          imgsrc: require('../assets/imgs/公司优势3.jpg')
        },
        {
          id: 4,
          title: '资质齐全',
          smtitle: '专注冷链服务',
          imgsrc: require('../assets/imgs/公司优势4.jpg')
        }
      ],
      // 更新日志数据
      updatelogs: [
        {
          id: 1,
          day: 23,
          year: '2021-06',
          updatetitle: '赤途冷链又更新了',
          updatewrite: '赤途冷链在60月23日完成了版本更新，本次主要...',
          toupdatepath: '#/new1'
        },
        {
          id: 2,
          day: 2,
          year: '2021-06',
          updatetitle: '赤途承运端又加了落地配功能了',
          updatewrite: '为了用户的运输成本降低、提高物流公司的运输效率..',
          toupdatepath: '#/news'
        },
        {
          id: 3,
          day: 17,
          year: '2021-05',
          updatetitle: '赤途供应链有上线了新的线路',
          updatewrite: '对于干线运输，赤途用最低的干线价格和最好的服务..',
          toupdatepath: '#/news2'
        },
        {
          id: 4,
          day: 15,
          year: '2021-04',
          updatetitle: '赤途冷链更新了',
          updatewrite: '赤途冷链在4月15日完成了版本更新，本次主要..',
          toupdatepath: '#/news3'
        }
      ]
    }
  },
  // components: { lunbotu },
  methods: {
    onSubmit () {
      console.log('submit!')
    }
    // tonews () {
    //   this.$route.push({
    //     path: '/news'
    //   })
    // }
  }
}
</script>
<style>
el-col{
  display: flex;
}
</style>
