<template>
  <el-container>
    <el-main>
      <el-row>
      <el-col :span="24">
        <el-row>
          <el-col :span="5"  class="photo">
            <img class="imgsty" src="../assets/imgs/我的.jpg" />
          </el-col>
          <el-col :span="6" :push="1" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">我的页面</span><br />
              <el-row class="smwrite"><span class="smfont">点击认证区域的立即认证按钮或点击我的功能里的切换身份</span></el-row>
             </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="7" class="photo">
            <img class="imgsty" src="../assets/imgs/角色身份.jpg" />
          </el-col>
          <el-col :span="6" :push="8" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">角色身份</span><br />
              <el-row class="smwrite"><span class="smfont">进入角色身份页面，点击下方的企业认证按钮</span></el-row>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5"  class="photo">
            <img class="imgsty" src="../assets/imgs/绑定角色.jpg" />
          </el-col>
          <el-col :span="7" :push="1" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">绑定角色</span><br />
              <el-row class="smwrite">
                <span class="smfont">
                  此时您是某企业的员工身份,选择3PL(公司总经理)、调度(调度员调度车辆)业务(业务员下单)这三种身份的一种，填入公司企业在后台开的账号的密码，此时账号绑定成功
                </span>
              </el-row>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="7" class="photo">
            <img class="imgsty" src="../assets/imgs/相互切换.jpg" />
          </el-col>
          <el-col :span="6" :push="8" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">相互切换</span><br />
              <el-row class="smwrite"><span class="smfont">当您有了企业员工账号也可以切回司机身份去接单</span></el-row>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'carrierchild1'
}
</script>

<style>
  .smwrite{
    margin-top: 10px;
    color: #999999;
  }
  .smfont{
    font-size: 15px;
  }
  .imgsty{
    width: 130%;
    height: 130%;
  }
</style>
