<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="24">
          <el-row id="app">
            <!-- 导航左侧图标 -->
            <el-col :span="8" :push="5">
              <img src="./assets/imgs/导航图标1.png" style="width: 40%;height: 40%;"/>
            </el-col>
            <!-- 导航右侧内容 -->
            <el-col :span="8" :push="6"   style="margin-top: 10px;">
              <el-row>
                <!-- <el-col :span="8" :push="11"><a href="#/" :style="astyle"  @click="changecolor()">首页</a></el-col> -->
                <el-col :span="6" :push="3"  :offset="2"><a href="#/" style="color: #000000;">首页</a></el-col>
                <el-col :span="6"   :offset="1" :push="1"><a href="#/user" style="color: #000000;">货主端指南</a></el-col>
                <el-col :span="6"  :push="1"  :offset="1"><a href="#/carrier" style="color: #000000;">承运端指南</a></el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <router-view/>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
// document.title = '赤途冷链'
export default {
  name: 'App',
  data () {
    return {
      active: 0
    }
  },
  methods: {
    // changecolor () {
    //   if (this.$route.path === '/') {
    //     this.astyle.color = 'red'
    //   }
    //   if (this.$route.path === '/user') {
    //     this.bstyle.color = 'red'
    //   }
    //   if (this.$route.path === '/carrier') {
    //     this.cstyle.color = 'red'
    //   }
    // }
  }
}
</script>
<style type="text/javascript">
a:link{
  text-decoration: none;
}
a:active{
  color: #00FFFF;
  text-decoration: none;
}
a:hover{
  color: #3A8EE6;
}
a{
  font-size: 18px;
}
</style>
