<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="24">
          <img  src="../assets/imgs/企业新闻大图.jpg" style="width: 100%;height: 100%;"/>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'newheadphoto'
}
</script>

<style>
</style>
