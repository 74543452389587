import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import user from '../components/user.vue'
import userchild1 from '../components/userchild1.vue'
import userchild2 from '../components/userchild2.vue'
import userchild3 from '../components/userchild3.vue'
import userchild4 from '../components/userchild4.vue'
import userchild5 from '../components/userchild5.vue'
import userchild6 from '../components/userchild6.vue'
import carrierchild1 from '../components/carrierchild1.vue'
import carrierchild2 from '../components/carrierchild2.vue'
import carrierchild3 from '../components/carrierchild3.vue'
import carrierchild4 from '../components/carrierchild4.vue'
import carrierchild5 from '../components/carrierchild5.vue'
import carrierchild6 from '../components/carrierchild6.vue'
import carrierchild7 from '../components/carrierchild7.vue'
import carrier from '../components/carrier.vue'
import lunbotu from '../components/lunbotu.vue'
import zoumadeng from '../components/zoumadeng.vue'
import news from '../views/news.vue'
import hangyezhishi from '../views/hangyezhishi.vue'
import newheadphoto from '../components/newheadphoto.vue'
import new1 from '../views/new1.vue'
import news2 from '../views/news2.vue'
import news3 from '../views/news3.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/news3',
    component: news3
  },
  {
    path: '/new1',
    component: new1
  },
  {
    path: '/news2',
    component: news2
  },
  {
    path: '/hangyezhishi',
    component: hangyezhishi
  },
  {
    path: '/newheadphoto',
    component: newheadphoto
  },
  {
    path: '/news',
    component: news
  },
  {
    path: '/user',
    component: user
  },
  {
    path: '/carrier',
    component: carrier
  },
  {
    path: '/userchild1',
    name: 'userchild1',
    component: userchild1
  },
  {
    path: '/userchild2',
    name: 'userchild2',
    component: userchild2
  },
  {
    path: '/userchild3',
    name: 'userchild3',
    component: userchild3
  },
  {
    path: '/userchild4',
    name: 'userchild4',
    component: userchild4
  },
  {
    path: '/userchild5',
    name: 'userchild5',
    component: userchild5
  },
  {
    path: '/userchild6',
    name: 'userchild6',
    component: userchild6
  },
  {
    path: 'carrierchild1',
    name: 'carrierchild1',
    component: carrierchild1
  },
  {
    path: 'carrierchild2',
    name: 'carrierchild2',
    component: carrierchild2
  },
  {
    path: 'carrierchild3',
    name: 'carrierchild3',
    component: carrierchild3
  },
  {
    path: 'carrierchild4',
    name: 'carrierchild4',
    component: carrierchild4
  },
  {
    path: 'carrierchild5',
    name: 'carrierchild5',
    component: carrierchild5
  },
  {
    path: 'carrierchild6',
    name: 'carrierchild6',
    component: carrierchild6
  },
  {
    path: 'carrierchild7',
    name: 'carrierchild7',
    component: carrierchild7
  },
  {
    path: 'lunbotu',
    name: 'lunbotu',
    component: lunbotu
  },
  {
    path: 'zoumadeng',
    name: 'zoumadeng',
    component: zoumadeng
  }
]
const router = new VueRouter({
  routes
})
export default router
