<template>
  <el-container>
    <el-main>
      <el-row>
      <el-col :span="24">
        <el-row>
          <el-col :span="5"  class="photo">
            <img class="imgsty" src="../assets/imgs/货主端指南7.jpg" />
          </el-col>
          <el-col :span="6" :push="1" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">零担列表</span><br />
              <el-row style="margin-top: 20px;" class="smfont"><span >选择某条线路，点击进零担的详情</span></el-row>
             </el-row>
          </el-col>
        </el-row>
        <!-- <el-row style="margin-top: 30px;">
          <el-col :span="6" :push="5" class="photo">
            <img src="../assets/imgs/货主端指南下单.jpg" />
          </el-col>
          <el-col :span="6" :push="4">
            <el-row style="margin-top: 70px;line-height: 30px;">
              <span style="font-size: 21px;">下单</span><br />
              <div style="margin-top: 20px;"><span class="smfont">选择提货方式和送货方式，如选上门提货和送货上门，需要填写地址（可选择多提多配）、填写货物信息、选择温度，点击下单，点击弹窗里的确定跳转到支付页面</span></div>
            </el-row>
          </el-col>
        </el-row> -->
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="7" class="photo">
            <img class="imgsty" src="../assets/imgs/货主端指南下单.jpg" />
          </el-col>
          <el-col :span="7" :push="8" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">下单</span><br />
              <div style="margin-top: 20px;" class="smfont"><span >选择提货方式和送货方式，如选上门提货和送货上门，需要填写地址（可选择多提多配）、填写货物信息、选择温度，点击下单，点击弹窗里的确定跳转到支付页面</span></div>
            </el-row>
          </el-col>
        </el-row>
        <!-- <el-row style="margin-top: 30px;">
          <el-col :span="6" class="photo">
            <img src="../assets/imgs/货主端指南5.jpg" />
          </el-col>
          <el-col :span="6" >
            <el-row style="margin-top: 80px;line-height: 30px;">
              <span style="font-size: 21px;">支付</span><br />
              <div style="margin-top: 20px;"><span class="smfont">选择支付方式，点击确认支付，当支付过后就会跳转到支付详情页面</span></div>
            </el-row>
          </el-col>
        </el-row> -->
        <el-row style="margin-top: 30px;">
          <el-col :span="5"  class="photo">
            <img class="imgsty" src="../assets/imgs/货主端指南5.jpg" />
          </el-col>
          <el-col :span="6" :push="1" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">支付</span><br />
               <div style="margin-top: 20px;" class="smfont"><span >选择支付方式，点击确认支付，当支付过后就会跳转到支付详情页面</span></div>
            </el-row>
          </el-col>
        </el-row>
        <!-- <el-row style="margin-top: 60px;">
          <el-col :span="6" :push="5" class="photo">
            <img src="../assets/imgs/货主端指南6.jpg" />
          </el-col>
          <el-col :span="6" :push="6">
            <el-row style="margin-top: 130px;line-height: 40px;">
              <span style="font-size: 21px;">支付详情</span><br />
              <div style="margin-top: 10px;"><span class="smfont">可查看支付详情</span></div>
            </el-row>
          </el-col>
        </el-row> -->
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="7" class="photo">
            <img class="imgsty" src="../assets/imgs/货主端指南6.jpg" />
          </el-col>
          <el-col :span="6" :push="8" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">支付详情</span><br />
              <div style="margin-top: 10px;" class="smfont"><span >可查看支付详情</span></div>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'userchild2'
}
</script>

<style>
  .smfont{
    color: #303133;
  }
  .photo img{
    border: 3px solid #D3DCE6;
  }
  .smfont span{
    font-size: 16px;
  }
  .imgsty{
    width: 130%;
    height: 130%;
  }
</style>
