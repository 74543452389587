<template>
  <el-container>
    <el-main>
      <el-row>
      <el-col :span="24" >
        <el-row>
          <el-col :span="5"  class="photo">
            <img class="imgsty" src="../assets/imgs/货主端指南下单.jpg" />
          </el-col>
          <el-col :span="6" :push="1" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">我的页面</span><br />
              <el-row style="margin-top: 20px;" class="smfont"><span >点击认证区域的立即认证按钮或点击我的功能里的切换身份</span></el-row>
            </el-row>
          </el-col>
        </el-row>
        <!-- <el-row style="margin-top: 30px;">
          <el-col :span="6" :push="5" class="photo">
            <img src="../assets/imgs/角色身份.jpg"/>
          </el-col>
          <el-col :span="6" :push="5">
            <el-row style="margin-top: 90px;line-height: 30px;">
              <span style="font-size: 21px;">角色身份</span><br />
              <div style="margin-top: 20px;"><span class="smfont">进入角色身份页面，点击下方的企业认证按钮</span></div>
            </el-row>
          </el-col>
        </el-row> -->
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="5" class="photo">
            <img class="imgsty" src="../assets/imgs/角色身份.jpg" />
          </el-col>
          <el-col :span="6" :push="6" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">角色身份</span><br />
              <div style="margin-top: 20px;" class="smfont"><span >进入角色身份页面，点击下方的企业认证按钮</span></div>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="7" class="photo">
            <img class="imgsty" src="../assets/imgs/企业认证.jpg" />
          </el-col>
          <el-col :span="6" :push="8" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">企业认证</span><br />
              <div style="margin-top: 20px;" class="smfont"><span >填写资料，点击提交</span></div>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="4" class="photo">
            <img class="imgsty" src="../assets/imgs/企业认证.jpg" />
          </el-col>
          <el-col :span="6" :push="5" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">认证详情</span><br />
              <div style="margin-top: 10px;" class="smfont"><span >当认证通过时就有了货主公司的住账号，此账号可以登录后台给员工开账号</span></div>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="2" class="photo">
            <img class="imgsty" src="../assets/imgs/相互切换.jpg" />
          </el-col>
          <el-col :span="6" :push="3" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">相互切换</span><br />
              <div style="margin-top: 10px;"  class="smfont"><span>当您有了企业账号也可以切回个人货主身份去下单</span></div>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'userchild3'
}
</script>

<style>
  .smfont{
    color: #303133;
  }
  .photo img{
    border: 3px solid #D3DCE6;
  }
  .imgsty{
    width: 130%;
    height: 130%;
  }
  .smfont span{
    font-size: 16px;
  }
</style>
