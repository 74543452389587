<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="24">
          <!-- 头部大图 -->
          <el-row>
            <el-col :span="24">
              <img src="../assets/imgs/企业新闻大图.jpg" style="width: 100%;height: 100%;" />
            </el-col>
          </el-row>
         <!-- 大标题 -->
          <el-row style="margin-top: 30px;text-align: center;">
            <el-col :span="24">
              <h2 style="font-size: 18px;">赤途承运端又加落地配功能了</h2>
            </el-col>
          </el-row>
          <!-- 正文 -->
          <el-row style="line-height: 40px;color: #82848A;">
            <el-col :span="10" :push="7">
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;赤途冷链为了可以让用户以更优惠的价格下零担订单增加了落地配功能。物流公司做干线物流的线路提货和配送费较高，但是，一些物流公司做城配更擅长，在入驻了APP后，货主可以看到更优惠的线路在上面下单。
              </span><br />
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;听到这里是不是有些心动呢，感兴趣的话去官网首页底部扫码下载货主端、承运端或在应用商店里搜索赤途冷链、赤途承运端。</span>
            </el-col>
          </el-row>
          <!-- 下方图片 -->
          <el-row style="margin-top: 30px;text-align: center;">
            <el-col :span="10" :push="7">
              <img src="../assets/imgs/企业新闻汽车图.jpg" style="width: 100%;height: 100%;" />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
}
</script>

<style>
</style>
