<template>
  <el-container>
    <el-main>
      <el-row>
      <el-col :span="24">
        <el-row>
          <el-col :span="5"  class="photo">
            <img class="imgsty" src="../assets/imgs/接单列表.png" />
          </el-col>
          <el-col :span="6" :push="1" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">接单列表</span><br />
              <el-row class="smwrite"><span >在接单列表里选择一个订单，点击进入订单详情页面</span></el-row>
             </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="5" class="photo">
            <img class="imgsty" src="../assets/imgs/订单详情.jpg" />
          </el-col>
          <el-col :span="6" :push="6" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">订单详情</span><br />
              <el-row class="smwrite" ><span>点击某个订单可看详细信息，决定自己是否去接这个订单</span></el-row>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="4" class="photo">
            <img class="imgsty" src="../assets/imgs/接单.png" />
          </el-col>
          <el-col :span="6" :push="5" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">接单</span><br />
              <el-row class="smwrite" ><span >点击接单按钮，点击确定，此订单接单成功，在我的--调度可查自己刚刚接的订单</span></el-row>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'carrierchild1'
}
</script>

<style>
  .smwrite{
    margin-top: 10px;
  }
  .imgsty{
    width: 130%;
    height: 130%;
  }
  .smwrite span{
    font-size: 16px;
    color: #000000;
  }
</style>
