<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <!-- 首页轮播图 -->
           <el-carousel indicator-position="outside" height="20vw" interval="2000">
                <el-carousel-item v-for="(item,index) in imgs" :key="index">
                    <img style="width: 100%;height: 100%;" :src='item.imgsrc' />
                </el-carousel-item>
            </el-carousel>
            <!-- <el-carousel :interval="2000" type="card" height="20vw">
                <el-carousel-item v-for="(item,index) in imgs" :key="index">
                  <img style="width: 100%;height: 100%;" :src='item.imgsrc' />
                </el-carousel-item>
              </el-carousel> -->
          </div>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'lunbotu',
  data () {
    return {
      imgs: [
        { imgsrc: require('../assets/imgs/轮播图1.jpg') },
        { imgsrc: require('../assets/imgs/轮播图2.jpg') },
        { imgsrc: require('../assets/imgs/轮播图3.jpg') }
      ]
    }
  }
}
</script>

<style>
</style>
