<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="24">
          <!-- 头部大图 -->
          <el-row>
            <el-col :span="24">
              <img src="../assets/imgs/企业新闻大图.jpg" style="width: 100%;height: 100%;" />
            </el-col>
          </el-row>
         <!-- 大标题 -->
          <el-row style="margin-top: 30px;text-align: center;">
            <el-col :span="24">
              <h2 style="font-size: 18px;">赤途冷链又更新了</h2>
            </el-col>
          </el-row>
          <!-- 正文 -->
          <el-row style="line-height: 40px;color: #82848A;">
            <el-col :span="10" :push="7">
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;赤途冷链在6月23日完成了版本更新，这次主要是对一些小场景进行优化，用户体验度更好，增加了一些小功能例如消息推送、声音播报。
              </span><br />
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;听到这里是不是有些心动呢，感兴趣的话去官网首页底部扫码下载货主端、承运端或在应用商店里搜索赤途冷链、赤途承运端。
              </span>
            </el-col>
          </el-row>
          <!-- 下方图片 -->
          <el-row style="margin-top: 40px;text-align: center;">
            <el-col :span="10" :push="7">
              <img src="../assets/imgs/企业新闻图2.jpg" style="width: 100%;height: 100%;" />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
}
</script>

<style>
  span {
    font-size: 15px;
  }
</style>
