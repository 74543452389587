<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="24">
          <!-- 头部大图 -->
          <el-row>
            <el-col :span="24">
              <img src="../assets/imgs/企业新闻大图.jpg" style="width: 100%;height: 100%;" />
            </el-col>
          </el-row>
         <!-- 大标题 -->
          <el-row style="margin-top: 30px;text-align: center;">
            <el-col :span="24">
              <h2 style="font-size: 18px;">赤途冷链更新了</h2>
            </el-col>
          </el-row>
          <!-- 正文 -->
          <el-row style="line-height: 40px;color: #82848A;">
            <el-col :span="10" :push="7">
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;赤途冷链在4月25日完成了自2021首次根据新的业务逻辑更新APP这重大举措，用过我们以往的APP的用户都知道，现在冷链业务多元化，原APP已经不能满足我们货主和物流公司的需求，对此赤途冷链进行了新的业务逻辑梳理，保证用户在使用APP可以符合现在冷链的业务场景。
              </span><br />
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                相比较以前的APP，现在APP下单更方便简洁，整车下单分为城际下单和同城下单支持多发多收，零担下单支持多提多配的区域在同一区域内。对于身份，货主可以免费申请企业货主身份来用我们的后台系统，司机可以免费升级物流公司，身份之间可以互相切换，大大增加了办事效率。
              </span><br />
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                听到这里是不是有些心动呢，感兴趣的话去官网首页底部扫码下载货主端、承运端或在应用商店里搜索赤途冷链、赤途承运端。
              </span>
            </el-col>
          </el-row>
          <!-- 下方图片 -->
          <el-row style="margin-top: 40px;text-align: center;">
            <el-col :span="10" :push="7">
              <img src="../assets/imgs/冷链物流时代.jpg" style="width: 100%;height: 100%;" />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
}
</script>

<style>
</style>
