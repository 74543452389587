<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="24">
          <!-- 头部大图 -->
          <el-row>
            <el-col :span="24">
              <img src="../assets/imgs/企业新闻大图.jpg" style="width: 100%;height: 100%;" />
            </el-col>
          </el-row>
         <!-- 大标题 -->
          <el-row style="margin-top: 30px;text-align: center;">
            <el-col :span="24">
              <h2 style="font-size: 18px;">赤途供应链又上线了新的线路</h2>
            </el-col>
          </el-row>
          <!-- 正文 -->
          <el-row style="line-height: 40px;color: #82848A;">
            <el-col :span="16" :push="4">
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;赤途冷链在4月25日完成了自2021首次根据新的业务逻辑更新APP这重大举措后，又紧接着上线了更多的零担线路，具体有哪些呢，让我们来看看吧。
              </span>
            </el-col>
          </el-row>
          <!-- 下方图片 -->
          <el-row style="margin-top: 40px;text-align: center;">
            <el-col :span="16" :push="4">
              <img src="../assets/imgs/表格1.png" style="width: 100%;height: 100%;" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 40px;text-align: center;">
            <el-col :span="16" :push="4">
              <img src="../assets/imgs/表格2.png" style="width: 100%;height: 100%;" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 40px;text-align: center;">
            <el-col :span="16" :push="4">
              <img src="../assets/imgs/表格3.png" style="width: 100%;height: 100%;" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 40px;text-align: center;">
            <el-col :span="16" :push="4">
              <img src="../assets/imgs/表格4.png" style="width: 100%;height: 100%;" />
            </el-col>
          </el-row>
          <el-row style="margin-top: 40px;text-align: center;">
            <el-col :span="16" :push="4">
              <img src="../assets/imgs/表格5.png" style="width: 100%;height: 100%;" />
            </el-col>
          </el-row>
          <el-row style="line-height: 40px;color: #82848A;">
            <el-col :span="16" :push="4">
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;由于篇幅问题只能展示这么多，感兴趣的话去官网首页底部扫码下载货主端、承运端或在应用商店里搜索赤途冷链、赤途承运端。去看完整的零担线路，可以在线路上下单。
              </span>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
}
</script>

<style>
</style>
