<template>
  <el-container>
    <el-main>
      <el-row>
      <el-col :span="24">
        <el-row>
          <el-col :span="5"  class="photo">
            <img class="imgsty" src="../assets/imgs/货主端指南1.jpg" />
          </el-col>
          <el-col :span="8" :push="1" :offset="1">
            <el-row style="margin-top: 50px;line-height: 35px;" class="smfont">
              <span >(1)参考车型的参数，根据自己货物体积和重量滑动选择一个合适的车型</span>
              <span >(2)选择城际或同城运输</span><br />
              <span  style="color: deepskyblue;">注：两种不同的计费方式，当两点在同一城市，选择同城会更便宜一些；两点在不同的城市，选择城际更划算</span><br />
              <span >(3)当有多个运输地点的时候，点击添加</span><br />
              <span >(4)点击地址会有常用地址和地图选择两种选择</span>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="5" class="photo">
            <img class="imgsty" src="../assets/imgs/常用地址1.jpg" />
          </el-col>
          <el-col :span="6" :push="6" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">常用地址</span><br />
              <div style="margin-top: 20px;" class="smfont"><span >当选择常用地址时可以选择以前添加的，也可以添加新的地址</span><br /></div>
              <span style="color: deepskyblue;" >注：一定是正确的联系方式，如果一不小心填错了，已经下单了，请及时联系平台</span><br />
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="3" class="photo">
            <img class="imgsty" src="../assets/imgs/常用地址1.jpg" />
          </el-col>
          <el-col :span="6" :push="4" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">常用地址</span><br />
              <div style="margin-top: 20px;" class="smfont"><span >选择地址的省市区，填写详细地址，填写联系人和联系电话</span><br /></div>
              <span style="color:deepskyblue;">注：一定是正确的联系方式，如果一不小心填错了，已经下单了，请及时联系平台</span><br />
            </el-row>
          </el-col>
        </el-row>
        <!-- <el-row style="margin-top: 30px;">
          <el-col :span="6" :push="2" class="photo">
            <img src="../assets/imgs/常用地址1.jpg" style="214px;height: 383px;"/>
          </el-col>
          <el-col :span="6" :push="2">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">地图选址</span><br />
              <div style="margin-top: 20px;"><span>选择地址的省市区，填写详细地址，填写联系人和联系电话</span><br /></div>
              <span style="color: deepskyblue;">注：一定是正确的联系方式，如果一不小心填错了，已经下单了，请及时联系平台</span><br />
            </el-row>
          </el-col>
        </el-row> -->
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="2" class="photo">
            <img class="imgsty" src="../assets/imgs/常用地址1.jpg" />
          </el-col>
          <el-col :span="6" :push="3" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">地图选址</span><br />
              <div style="margin-top: 20px;" class="smfont"><span >选择地址的省市区，填写详细地址，填写联系人和联系电话</span><br /></div>
              <span style="color: deepskyblue;">注：一定是正确的联系方式，如果一不小心填错了，已经下单了，请及时联系平台</span><br />
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="5" class="photo">
            <img class="imgsty" src="../assets/imgs/我要用车页面.png" />
          </el-col>
          <el-col :span="6" :push="6" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">我要用车页面</span><br />
              <div style="margin-top: 20px;" class="smfont"><span >当填写完地址后就会显示预估里程和预估运费，点击我要用车跳转到下单页面</span><br /></div>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="7" class="photo">
            <img class="imgsty" src="../assets/imgs/下单.png" />
          </el-col>
          <el-col :span="6" :push="8" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">下单页面</span><br />
              <div style="margin-top: 20px;" class="smfont"><span >填写货物信息包括件数、吨数、立方数和温度，选择装车时间、现在收货时间、选择是否装卸，填写备注，填写期望运费，点击确定跳转到支付页面</span></div>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="5" class="photo">
            <img class="imgsty" src="../assets/imgs/货主端指南5.jpg" />
          </el-col>
          <el-col :span="6" :push="6" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <div style="margin-top: 20px;" class="smfont"><span >选择支付方式，点击确认支付便跳转第三方付款完成显示支付成功的详情页面</span></div>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="4" class="photo">
            <img class="imgsty" src="../assets/imgs/货主端指南6.jpg" />
          </el-col>
          <el-col :span="6" :push="5" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">支付成功页面</span><br />
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'carrierchild6'
}
</script>

<style>
  .smfont{
    font-size: 17px;
  }
  .imgsty{
    width: 130%;
    height: 130%;
  }
  .smfont span{
    font-size: 16px;
  }
</style>
