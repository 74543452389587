<template>
  <el-container>
    <el-main>
      <!-- 图片 -->
      <el-row>
        <el-col :span="24" >
          <img src="../assets/imgs/货主端指南大图.jpg" style="height: 100%;width: 100%;"/>
          <!-- <lunbotu></lunbotu> -->
        </el-col>
      </el-row>
      <!-- 主体 -->
      <el-row style="margin-top: 50px;">
        <el-col :span="24">
          <el-row>
            <!-- 左侧菜单 -->
            <el-col  :span="4" :push="4" style="line-height: 50px;background-color: #F5F5F5;">
              <ul style="list-style-type: none;border: 1xp solid;font-size: 16px;" v-for="(item,index) in leftlist" :key="index">
                <li :class="active == index ? 'isActive' : '' "  @click="swich(index)">{{item.lefttitle}}</li>
              </ul>
              <!-- <el-row>
                <el-col :span="9" style="margin-left: 350px;line-height: 50px;background-color: #F5F5F5;"> -->
                  <!-- <router-link  to="/userchild1">组件1</router-link> -->
                  <!-- <ul style="list-style-type: none;border: 1xp solid;" v-for="(item,index) in leftlist" :key="index">
                    <li :class="active == index ? 'isActive' : '' "  @click="swich(index)">{{item.lefttitle}}</li>
                  </ul>
                </el-col>
              </el-row> -->
            </el-col>
            <!-- 右侧内容 -->
            <el-col :span="15" :push="3" :offset="2" >
              <!-- <router-view></router-view> -->
              <el-row>
                <el-col v-if="p==0">
                  <!-- <router-link  to="/userchild1">组件1</router-link> -->
                  <userchild1></userchild1>
                </el-col>
                <el-col v-if="p==1">
                  <userchild2></userchild2>
                </el-col>
                <el-col v-if="p==2">
                  <userchild3></userchild3>
                </el-col>
                <el-col v-if="p==3">
                  <userchild4></userchild4>
                </el-col>
                <el-col v-if="p==4">
                  <userchild5></userchild5>
                </el-col>
                <el-col v-if="p==5">
                  <userchild6></userchild6>
                </el-col>
              </el-row>
              <!-- <userchild1></userchild1> -->
            </el-col>
          </el-row>
      </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
// import lunbotu from './lunbotu'
import userchild1 from './userchild1'
import userchild2 from './userchild2'
import userchild3 from './userchild3'
import userchild4 from './userchild4'
import userchild5 from './userchild5'
import userchild6 from './userchild6'
export default {
  data () {
    return {
      active: -1,
      leftlist: [
        { lefttitle: '如何下整车单' },
        { lefttitle: '如何下零担单' },
        { lefttitle: '如何认证货主公司' },
        { lefttitle: '如何绑定货主公司' },
        { lefttitle: '如何联系平台' },
        { lefttitle: '如何联系运输司机' }
      ],
      p: 0
    }
  },
  components: { userchild1, userchild2, userchild3, userchild4, userchild5, userchild6 },
  methods: {
    swich (index) {
      this.p = index
      // console.log(this.i)
      this.active = index
    }
  }
}
</script>

<style>
  ul li{
    color: #66666;
    margin-left: -15px;
  }
  .isActive{
    color: #409EFF;
  }
</style>
