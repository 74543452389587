<template>
  <el-container>
    <el-main>
      <el-row>
      <el-col :span="24">
        <el-row>
          <el-col :span="5"  class="photo">
            <img class="imgsty" src="../assets/imgs/我的.jpg" />
          </el-col>
          <el-col :span="6" :push="1" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">我的页面</span><br />
              <el-row style="margin-top: 20px;" class="smfont"><span >点击认证区域的立即认证按钮或点击我的功能里的切换身份</span></el-row>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5" :push="7" class="photo">
            <img class="imgsty" src="../assets/imgs/设置.jpg" />
          </el-col>
          <el-col :span="6" :push="8" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">设置</span><br />
              <div style="margin-top: 20px;" class="smfont"><span>点击关于我们，进入关于我们页面</span></div>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 30px;">
          <el-col :span="5"  class="photo">
            <img class="imgsty" src="../assets/imgs/关于我们.jpg" />
          </el-col>
          <el-col :span="6" :push="1" :offset="1">
            <el-row style="margin-top: 50px;line-height: 30px;">
              <span style="font-size: 21px;">关于我们</span><br />
              <div style="margin-top: 10px;" class="smfont"><span >当您有任何关于物流的问题、关于我们软件问题都可以来电咨询，我们一定会给您最满意的答复</span></div>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'userchild4'
}
</script>

<style>
  .photo img{
    border: 3px solid #D3DCE6;
  }
  .smfont{
    color: #303133;
  }
  .imgsty{
    width: 130%;
    height: 130%;
  }
  .smfont span{
    font-size: 16px;
  }
</style>
